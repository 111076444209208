import { isOverflowing } from 'initializers/printer/page'
import { findSharedParent, removeBrokenElement, selectByDataRef, clearPathTo } from 'initializers/printer/dom'

export function reversedWords(textNode) {
  return textNode.textContent
    .split(/\s/)
    .filter((word) => word.trim().length > 0)
    .reverse()
}

export function splitOnWord(textNode, word) {
  const originalText = textNode.textContent
  const wordStart = originalText.lastIndexOf(word)

  textNode.textContent = originalText.substring(0, wordStart)

  const movedText = originalText.substring(wordStart, originalText.length)
  return { movedText, wordStart }
}

export function addMovedTextToParent(newContainer, oldContainer, movedText, textNode) {
  const originalParent = textNode.parentElement
  const newTextNode = textNode.cloneNode()
  newTextNode.textContent = movedText

  const { newParent, movedSubtree } = findSharedParent(textNode, oldContainer, newContainer)
  if (movedSubtree.parentElement === originalParent) {
    if (newParent.childNodes.length > 0) {
      if (newParent.childNodes[0].nodeName === '#text') {
        newParent.childNodes[0].textContent = movedText + newParent.childNodes[0].textContent
      } else {
        newParent.prepend(newTextNode)
      }
    } else {
      newParent.appendChild(newTextNode)
    }
  } else {
    const newTextTree = movedSubtree.cloneNode(true)
    placeChild(newParent, newTextTree)
    const realParent = selectByDataRef(originalParent, newParent)

    clearPathTo(newTextTree, realParent)

    realParent.appendChild(newTextNode)
  }
}

function placeChild(parent, child) {
  if (parent.children.length === 0) {
    parent.prepend(child)
  } else {
    // find the sibling with a data ref that is closest to the new child
    const childRef = parseInt(child.getAttribute('data-ref').split('-')[1])
    const children = Array.from(parent.children)
    for (let i = 0; i < children.length; i++) {
      const siblingRef = parseInt(children[i].getAttribute('data-ref').split('-')[1])
      if (childRef < siblingRef) {
        if (i === 0) {
          parent.prepend(child)
        } else {
          parent.insertBefore(child, children[i])
        }
        return
      }
    }
    parent.appendChild(child)
  }
}

export function removeUnusedNodes(oldNode, wordStart) {
  if (wordStart === 0 || oldNode.textContent.trim().length === 0) {
    removeBrokenElement(oldNode)
  }
}

export function splitText(textNode, newChunk, page) {
  const words = reversedWords(textNode)

  const newTextNode = textNode.cloneNode()
  newTextNode.textContent = ''
  let movedWordStart = 0

  words.every((word) => {
    const { movedText, wordStart } = splitOnWord(textNode, word)
    newTextNode.textContent = movedText + newTextNode.textContent
    movedWordStart = wordStart
    return !(wordStart === 0 || !isOverflowing(page))
  })

  removeUnusedNodes(textNode, movedWordStart)
  newChunk.prepend(newTextNode)
}
