export function getBreakStyle(element) {
  const styles = window.getComputedStyle(element)
  return {
    before: styles.getPropertyValue('page-break-before'),
    inside: styles.getPropertyValue('page-break-inside'),
    after: styles.getPropertyValue('page-break-after'),
  }
}

export function isFlexRow(element) {
  const styles = window.getComputedStyle(element)
  return (
    styles.getPropertyValue('display') === 'flex' &&
    (styles.getPropertyValue('flex-direction') === 'row' || styles.getPropertyValue('flex-direction') === 'reverse-row')
  )
}

export function isGrid(element) {
  const styles = window.getComputedStyle(element)
  return styles.getPropertyValue('display') === 'grid' && !styles['gridTemplate'].includes('/ repeat(1,')
}

const CONTENT_TAGS = ['IMG', 'SVG', 'CANVAS', 'svg', 'TR']

export function hasContent(element) {
  return hasText(element) || isPrintedTag(element)
}

export function containsPrintedTag(element) {
  return CONTENT_TAGS.some((tag) => element.querySelector(tag.toLowerCase()))
}

export function hasTextInTree(element) {
  return Array.from(element.querySelectorAll('*')).some((node) => hasText(node))
}

export function hasText(element) {
  return Array.from(element.childNodes).some((node) => {
    return node.nodeName === '#text' && (node.textContent || '').trim().length > 0
  })
}

export function isPrintedTag(element) {
  return CONTENT_TAGS.includes(element.tagName || element.nodeName)
}

// only care about vertical since horizontal isn't fixed by a new page w/ our layouts
export function isOverflown({ clientHeight, scrollHeight }) {
  return isVerticallyScrolling({ scrollHeight, clientHeight })
}

export function isVerticallyScrolling({ scrollHeight, clientHeight }) {
  return scrollHeight > clientHeight
}

export function lowestChildNode(node) {
  const nodes = Array.from(node.childNodes)
  return nodes[nodes.length - 1]
}

export function parentBottomSpacing(node) {
  let spacing = 0
  let parent = node.parentElement
  while (parent && !parent.classList.contains('page-content')) {
    spacing = spacing + parseInt(window.getComputedStyle(parent).paddingBottom)
    spacing = spacing + parseInt(window.getComputedStyle(parent).marginBottom)
    parent = parent.parentElement
  }
  return spacing
}

export function nodeMargin(node) {
  if (node instanceof Element) {
    return parseInt(window.getComputedStyle(node).marginBottom || 0)
  }
  return 0
}

export function removeBrokenElement(element) {
  const parent = element.parentElement
  element.remove()
  removeEmptyParents(parent)
}

export function removeEmptyParents(parent) {
  while (parent && Array.from(parent.childNodes).length === 0 && !parent.classList.contains('page-content')) {
    const oldParent = parent
    parent = parent.parentElement
    oldParent.remove()
  }
}

export async function loadImages(element) {
  const images = (element.parentElement || element).querySelectorAll('img')
  const promises = []
  images.forEach((image) => {
    promises.push(
      new Promise((resolve) => {
        const img = new Image()
        img.onload = resolve
        img.onerror = resolve
        img.src = image.src
      }),
    )
  })
  for (const image of promises) {
    await image
  }
}

export function findSharedParent(element, oldPage, newPage) {
  let parent = element.parentElement
  let newParent = selectByDataRef(parent, newPage)

  while (!newParent && !!parent.getAttribute('data-ref')) {
    element = parent
    parent = parent.parentElement
    newParent = selectByDataRef(parent, newPage)
  }

  return { newParent, oldParent: parent, movedSubtree: element }
}

export function selectByDataRef(element, container = document) {
  return container.querySelector(`[data-ref="${element.getAttribute('data-ref')}"]`)
}

export function deepClone(element) {
  const clone = element.cloneNode(true)
  if (clone.nodeName === 'CANVAS') {
    cloneCanvas(element, clone)
  } else {
    clone.querySelectorAll('canvas').forEach((canvas) => {
      const originalCanvas = selectByDataRef(canvas, element)
      if (originalCanvas) {
        cloneCanvas(originalCanvas, canvas)
      }
    })
  }
  return clone
}

function cloneCanvas(originalCanvas, newCanvas) {
  const context = newCanvas.getContext('2d')
  context.drawImage(originalCanvas, 0, 0)
}

export function clearPathTo(element, targetElement) {
  element.querySelectorAll('*').forEach((child) => {
    if (!child.contains(targetElement)) {
      child.remove()
    }
    clearText(child)
  })
  clearText(element)
}

function clearText(element) {
  Array.from(element.childNodes).forEach((child) => {
    if (child.nodeName === '#text') {
      child.remove()
    }
  })
}
